import axios from 'axios';
import { _get, _includes } from 'utils/utils';
axios.defaults.baseURL = _includes(window.location.origin, 'localhost') ? window.location.origin : `https://api.eight.tech/`;

const api = {
  async get(url, query) {
    if (query) {
     const params = _get(query, 'params');
      // const bufferedQuery = bufferQuery(query);
      // return axios.get(`${url}`, { ...bufferedQuery });
      return axios.get(`${url}`, {
        params,
        paramsSerializer: function paramsSerializer(params) {
          return Object.entries(Object.assign({}, params)).map(([key, value]) => `${key}=${value}`).join('&');
        }
      });
    } else {
      return axios.get(`${url}`);
    }
  },
  async post(url, post) {
    return axios.post(`${url}`, post);
  },
  async put(url, put) {
    return axios.put(`${url}`, put);
  },
  async delete(url, del) {
    return axios.delete(`${url}`, { data: del });
  },
};

export {api};
