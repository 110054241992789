import React, {useEffect} from 'react';
/* styles */
import styled from 'styled-components/macro';
/* state management */
import {Observer, observer, useLocalStore} from 'mobx-react-lite';
/* utilities */
import Moment from 'moment';
import regression from 'regression';
import {_cloneDeep, _find, _get, useGetWidgetData, useInjectStores} from 'utils/utils';
/* components */
import {Dropdown, Error, LoadingSpinner, MultiSelectDropdown} from 'components/UI';
import {ComposedCharts} from './resources';
import {Square} from 'styled-icons/fa-regular/Square';
import {CheckIcon, WidgetWrapper} from 'styles/styledComponents';
import {widgetService} from 'services/service';

const widgetConstants = {name: 'ssTotalsGraph', id: 12};

const TrendsGraph = observer(() => {
    const {dataManagementStore, uiStore, utilsStore} = useInjectStores();

    const toCurrency = utilsStore.toCurrency,
        showSidebar = uiStore.showSidebar;

    const store = useLocalStore(() => ({
        loading: false,
        selectedChart: 'All',
        dateTypeNum: true,
        widgetData: null,
        error: null,
        granularity: 'days',
        avgsDisplay: {
            sale: 'sale_amt',
            decl: 'decl_amt',
            chgbk: 'net_chgbk_amt',
            ref: 'ref_amt'
        },
        defaultOptions: [
            {key: 1, text: 'All', value: 'All', checked: true},
            {key: 2, text: 'Sales', value: 'Sales', checked: false},
            {key: 3, text: 'Declines', value: 'Declines', checked: false},
            {key: 4, text: 'Returns', value: 'Returns', checked: false},
            {key: 5, text: 'Reserves', value: 'Reserves', checked: false},
        ],
        defaultNoReserveOpt: [
            {key: 1, text: 'All', value: 'All', checked: true},
            {key: 2, text: 'Sales', value: 'Sales', checked: false},
            {key: 3, text: 'Declines', value: 'Declines', checked: false},
            {key: 4, text: 'Returns', value: 'Returns', checked: false},
        ],
        get options() {
            return store.showReserves ? store.defaultOptions : store.defaultNoReserveOpt;
        },
        setGranularity(granularity) {
            if (store.granularity !== granularity) {
                store.granularity = granularity;
            }
        },
        granularityOptions: [
            {key: 1, text: 'Days', value: 'days', disable: false},
            {key: 2, text: 'Weeks', value: 'week', disable: false},
        ],
        avgsDisplayOptions: [
            {
                key: 1, text: 'Amount', value: {
                    sale: 'sale_amt',
                    decl: 'decl_amt',
                    chgbk: 'net_chgbk_amt',
                    ref: 'ref_amt'
                }, disable: false
            },
            {
                key: 2, text: 'Avgs', value: {
                    sale: 'sale_amt_avg',
                    decl: 'decl_amt_avg',
                    chgbk: 'chgbk_amt_avg',
                    ref: 'ref_avg'
                }, disable: false
            },
            {
                key: 3, text: 'Count', value: {
                    sale: 'sale_cnt',
                    decl: 'decl_cnt',
                    chgbk: 'net_chgbk_cnt',
                    ref: 'ref_cnt'
                }, disable: false
            },
        ],
        setDisplayOptions(option) {
            this.avgsDisplay = option
        },
        setGranularityOptions() {
            let startDt = Moment(store.newQuery.params.startDt);
            let endDt = Moment(store.newQuery.params.endDt);
            let diffDays = endDt.diff(startDt, 'days');
            let diffWeeks = endDt.diff(startDt, 'weeks');
            //exactly six months does not give weeks back
            if (diffDays <= 2) {
                store.granularityOptions = [{key: 1, text: 'Hours', value: 'hours', disable: true}];
                if (store.granularity !== 'hours') store.granularity = 'hours';
            }
            if (diffDays > 2 && diffWeeks <= 2) {
                store.granularityOptions = [{key: 1, text: 'Days', value: 'days', disable: false}];
                if (store.granularity !== 'days') store.granularity = 'days';
            }
            if (diffWeeks > 2 && diffWeeks <= 4) {
                store.granularityOptions = [
                    {key: 1, text: 'Days', value: 'days', disable: false},
                    {key: 2, text: 'Weeks', value: 'week', disable: false},
                ];
                if (store.granularity !== 'days' && store.granularity !== 'week') store.granularity = 'days';
            }
            if (diffWeeks > 4 && diffWeeks <= 53) {
                store.granularityOptions = [
                    {key: 1, text: 'Week', value: 'week', disable: false},
                    {key: 2, text: 'Month', value: 'month', disable: false},
                ];
                if (store.granularity !== 'week' && store.granularity !== 'month') store.granularity = 'week';
            }
            if (diffWeeks > 53 && diffWeeks < 104) {
                store.granularityOptions = [
                    {key: 1, text: 'Week', value: 'week', disable: false},
                    {key: 2, text: 'Month', value: 'month', disable: false},
                ];
                if (store.granularity !== 'week' && store.granularity !== 'month') store.granularity = 'week';
            }
            if (diffWeeks >= 104 && diffWeeks <= 120) {
                store.granularityOptions = [
                    {key: 1, text: 'Year', value: 'years', disable: false},
                    {key: 2, text: 'Month', value: 'month', disable: false},
                ];
                if (store.granularity !== 'years' && store.granularity !== 'month') store.granularity = 'years';
            }
        },

        get legendPayload() {
            const legend = [];
            for (let i = 0; i < store.selectedOptions.length; i++) {
                let opt = store.selectedOptions[i];
                if (opt === 'Sales') {
                    legend.push({id: opt, value: opt, type: 'circle', color: '#3fa9f5', size: 10, width: 10});
                    legend.push({id: `${opt} Trend`, value: 'S. Trend', type: 'line', color: '#3fa9f5'});
                }
                if (opt === 'Declines') {
                    legend.push({id: opt, value: opt, type: 'circle', color: '#45E885'});
                    legend.push({id: `${opt} Trend`, value: 'D. Trend', type: 'line', color: '#45E885'});
                }
                if (opt === 'Returns') {
                    legend.push({id: opt, value: opt, type: 'circle', color: '#fccc1a'});
                    legend.push({id: `${opt} Trend`, value: 'R.S. Trend', type: 'line', color: '#fccc1a'});
                }
                if (opt === 'Reserves') {
                    legend.push({id: opt, value: opt, type: 'circle', color: '#FF2B498'});
                    legend.push({id: `${opt} Trend`, value: 'R. Trend', type: 'line', color: '#FF2B498'});
                }
            }
            return legend;
        },
        // chartWidth: 0,
        /* get ticksPadding() {
              if (store.chartWidth === 0) {
                const chartWidth = document.getElementById('cGrid');
                if (chartWidth) {
                  store.chartWidth = chartWidth.offsetWidth;
                }
              }
              let calcChartWidth = 0;
              const ticksLength = store.ticks.length;
              switch (ticksLength) {
                case 2: {
                  calcChartWidth = 0.88;
                  break;
                }
                case 3: {
                  calcChartWidth = 0.4;
                  break;
                }
                case 4: {
                  calcChartWidth = 0.25;
                  break;
                }
                case 5: {
                  calcChartWidth = 0.8;
                  break;
                }
                case 6: {
                  calcChartWidth = 0.13;
                  break;
                }
                case 7: {
                  calcChartWidth = 0.1;
                  break;
                }
                case ticksLength >= 12: {
                  calcChartWidth = 0.0;
                  break;
                }
                default:
                  calcChartWidth = 0.05;
              }
              let width = store.chartWidth + store.chartWidth * calcChartWidth;
              let ratio = Math.ceil(width / store.ticks.length);
              return -1 * Math.ceil(ratio / 2);
            },*/
        get selectedOptions() {
            const selected = [];
            store.options.map((opt) => {
                if (opt.checked) {
                    selected.push(opt.text);
                }
            });
            return selected;
        },
        onChange(obj) {
            if (obj.text === 'All') {
                store.options.map((opt) => {
                    opt.checked = opt.text === 'All' ? (opt.checked = true) : (opt.checked = false);
                });
            } else {
                let allOption = _find(store.options, {key: 1});
                allOption.checked = false;

                let optionToChange = _find(store.options, {key: obj.key});
                optionToChange.checked = !optionToChange.checked;

                let checkAll = true;
                store.options.map((opt) => {
                    if (opt.checked === true) {
                        checkAll = false;
                    }
                });
                if (checkAll === true) {
                    let allOption = _find(store.options, {key: 1});
                    allOption.checked = true;
                }
            }
        },
        get newQuery() {
            const newQuery = _cloneDeep(dataManagementStore.newQuery);
            if (newQuery) {
                return {params: {...newQuery}};
            }
        },
        get showReserves() {
            let showReserves = false;
            let reservesData = _get(store, 'widgetData.data.reserves');
            if (reservesData) {
                reservesData.forEach((datum) => {
                    showReserves = datum.totalAmt > 0;
                });
            }
            return showReserves;
        },
        get allDashData() {
            if (_get(store, 'widgetData') && store.widgetData.length > 0) {
                let dataHolder = [];
                let SalesTrendline = [];
                let DeclinesTrendline = [];
                let ReservesTrendline = [];
                let ReturnsTrendline = [];
                for (let i = 0; i < store.widgetData.length; i++) {
                    let date = Moment(store.widgetData[i].txn_dt_eom, ['YYYY-MM-DD-HH', 'YYYY-MM-DD']);
                    const dtNum = date.format('MM');


                    dataHolder.push({
                        // dtWord,
                        dtNum,
                        ...store.widgetData[i],
                    });
                    SalesTrendline.push([i, dataHolder[i][store.avgsDisplay['sale']]]);
                    DeclinesTrendline.push([i, dataHolder[i][store.avgsDisplay['decl']]]);
                    ReturnsTrendline.push([i, dataHolder[i][store.avgsDisplay['ref']]]);
                    ReservesTrendline.push([i, dataHolder[i][store.avgsDisplay['chgbk']]]);
                }

                const salesRegression = regression.linear(SalesTrendline);
                const declinesRegression = regression.linear(DeclinesTrendline);
                const returnsRegression = regression.linear(ReturnsTrendline);
                const reservesRegression = regression.linear(ReservesTrendline);
                for (let i = 0; i < dataHolder.length; i++) {
                    dataHolder[i]['SalesTrendline'] = salesRegression.points[i][1];
                    dataHolder[i]['DeclinesTrendline'] = declinesRegression.points[i][1];
                    dataHolder[i]['ReturnsTrendline'] = returnsRegression.points[i][1];
                    dataHolder[i]['ChbgkTrendline'] = reservesRegression.points[i][1];
                }

                return dataHolder;
            }
        },
        get label() {
            return store['ssTotalsGraph.dateBucket'];
        },
        toggleDisable() {
            store.disable = !store.disable;
        },
        handleChange(value) {
            store.selectedChart = value;
        },
        toggleDateFormat() {
            store.dateTypeNum = !store.dateTypeNum;
        },
        setWidgetData(widgetData) {
            store.error = null;
            const {status, data, error} = widgetData;
            if (status === 'loading') this.loading = true;
            if (error) {
                this.error = error;
                this.loading = false;
            }
            if (data) {
                store.widgetData = data.values;
                store.setGranularityOptions();
                this.loading = false;
            }
        },
    }));

    const fetchData = useGetWidgetData(
        {name: 'dashboardTotalGraph', query: widgetService.analytics1},
        'data',
        dataManagementStore?.newQuery
    );


    useEffect(() => {
        store.setWidgetData(fetchData);
    }, [fetchData]);

    return (
        <Observer>
            {() => (
                <StyledWrapper moveLeft={!showSidebar}>

                    <div
                        css={`
                          grid-area: sales;
                        `}
                    >

                        <WidgetWrapper id="cGrid">

                            <ol>
                                <p style={{textAlign: 'center', fontWeight: 'bold'}}>Sales</p>
                                <li value="1">Hopefully sales are increasing over time</li>
                                <ol type="A">
                                    <li>You may not be getting same net growth as you expect... IF refunds and net chargebacks are increasing in volume and amounts.</li>
                                    <li>Additionally, as declines and errors grow, this represents lost opportunities of seeing higher sales and profit growth.</li>
                                    <li>As you look at the graphs, the trend lines for declines, refunds, and chargebacks are hopefully growing at a lesser slope than sales... and even better if the trend lines are sloping down</li>
                                    <li>We have noticed an industry-wide trend of the average ticket amount of declines, refunds, and chargebacks being of greater amounts than the average successful sales ticket.  This should be researched by your company... as this is an indicator of fraud by your customers and/or internal policy issues regarding credit limits, shipping procedures, and returns policies.</li>
                                </ol>
                            </ol>
                        </WidgetWrapper>
                    </div>
                    <div
                        css={`
                          grid-area: refunds;
                        `}
                    >
                        <WidgetWrapper id="cGrid">
                            <ol>
                                <p style={{textAlign: 'center', fontWeight: 'bold'}}>Declines</p>
                                <li value="2">Declines and errors represent lost sales and profit opportunities</li>
                                <ol type="A">
                                    <li>The "Transactions: Declines" section should be looked at each and every day. The longer it takes you to fix and resubmit a credit card transaction, the more difficult it becomes to successfully collect.</li>
                                    <li>At a minimum, you should stop running transactions on "Hard Decline" and non-fixable cards.  Each of the transactions have costs that the successful transactions have to absorb.</li>
                                    <StyledNote>
                                        <span className="note">*</span>
                                        <span className="noteBody">"Hard Declines" and non-fixable cards will never again have a successful transaction, you to get a replacement card from your customer.</span>
                                    </StyledNote>
                                    <li>You should not try to collect on declined transactions if NO products or services were delivered to the customer.</li>
                                </ol>
                            </ol>
                        </WidgetWrapper>
                    </div>
                    <div
                        css={`
                          grid-area: declines;
                        `}
                    >
                        <WidgetWrapper id="cGrid">
                            <ol>
                                <p style={{textAlign: 'center', fontWeight: 'bold'}}>Chargebacks</p>
                                <li value="3">Chargebacks should be taken seriously.  Many of these transactions indicate intentional fraud by your customer.</li>
                                <ol type="A">
                                    <li>You should look into each customer who files a chargeback to determine if this is a customer you should continue to conduct business with.</li>
                                    <li>Current statistics show that customers who are not confronted regarding their chargeback will likely repeat this pattern; and usually their future purchases will increase in dollar amount.</li>
                                    <li>If you do recurring billing, you should check all chargebacks for customers who stated that they cancelled their recurring billing. If you continue to bill them in the future, they will continue to file chargebacks and this will create "red flags" to the industry.</li>
                                    <li>Your allowable chargeback ratio against successful sales is 0.50% before the industry may increase your risk assessment fees. Please note that if your ratio goes too high the industry may put you on "Reserves" and/or shut off your merchant account.</li>
                                </ol>
                            </ol>
                        </WidgetWrapper>
                    </div>
                    <div
                        css={`
                          grid-area: chargebacks;
                        `}
                    >
                        <WidgetWrapper id="cGrid">
                            <ol>
                                <p style={{textAlign: 'center', fontWeight: 'bold'}}>Refunds</p>
                                <li value="4">Refunds are the most problematic type of transaction in the credit card industry.</li>
                                <ol type="A">
                                    <li>The more level-3 information you send with your refund, such as the original invoice number, the lower the odds of having issues.</li>
                                    <li>Our software tracks the refunds to the sale, if invoice information has been added. This allows us to notify you, if an original sale has been over-refunded and/or double refunded... especially if there is also a chargeback.</li>
                                    <li>Do not use the same original invoice number for a customer on multiple refunds, unless each refund actually applies to the sale, otherwise, item B above will not work.</li>
                                    <li>{`The credit card industry may penalize you if your total returns (which are refunds and chargebacks combined) reach >= 5%.  The industry may put you on "Reserves", increase risk assessment fees, and/or shut off your merchant account.`}</li>

                                </ol>
                            </ol>
                        </WidgetWrapper>
                    </div>
                </StyledWrapper>
            )}
        </Observer>
    );
});

export default TrendsGraph;

const StyledWrapper = styled.div`
  height: calc(100% - 35px);
  display: grid;
  grid-template-areas:
    'dropdown dropdown'
    'sales refunds'
    'declines chargebacks';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr 1fr;
  grid-gap: 5px;
  padding-bottom: 20px;

  ol {
    padding-right: 1rem;
  }
  ol>li {
    padding-top: 1rem;
  }

  position: relative;
  width: ${(props) => (props.moveLeft ? '100%' : 'calc(100vw - 210px)')};

  .center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .checkIcon {
    display: flex;
    position: relative;
    align-items: center;
  }

  .my-tooltip > .tooltip-inner {
    background-color: red;
  }

  #tooltip-top > .tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #062e56;
  }

  #tooltip-top > .tooltip-arrow {
    border-top: 5px solid #062e56;
  }

  .header {
    position: absolute;
    top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;

    div {
      display: flex;
      align-items: center;

      .filledCircle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: 0 3px 0 0;
      }

      .r {
        background-color: #ff2b49;
      }
    }
  }

  .noData {
    margin: auto;
  }

  .xAxisTick {
    &:hover {
      fill: ${({theme}) => theme.baseColors.colorBlue};
    }
  }
`;

const DropdownWrapper = styled.div`
  margin: 7px 0 0 9px;
  display: flex;
  align-items: center;
  color: #fff;
  z-index: 1;

  .toggleDateType {
    margin-left: 10px;
  }

  .dtLabel {
    padding-top: 2px;
    font-size: 18px;
    color: ${({theme}) => theme.colors.text};
  }
`;
const StyledNote = styled.div`
  display: flex;
  height: 100%;
  font-size: 12px;
  padding: ${({ padding }) => padding};
  align-items: flex-start;
  font-style: italic;
  .note {
    font-size: 12px;
    color: red;
  }
  .noteBody {
    color: ${({ theme }) => theme.baseColors.whiteAlpha8};
  }
`;