import React, {Fragment, useEffect} from 'react';
import {_get, _find, _includes, _toPairs, useGetWidgetData, useInjectStores} from 'utils/utils';
import {autorun} from 'mobx';
import {observer, Observer, useLocalObservable} from 'mobx-react-lite';
import {Button, LoadingSpinner} from 'components/UI';
import {
    ClearIcon,
    FilterIcon,
    HeaderCell,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHeader,
    TableRow,
    TableWrapper,
} from 'styles/styledComponents';
import {widgetService} from 'services';
import styled from "styled-components/macro";
import Moment from "moment/moment";

const Details = observer((props) => {
    const {dataManagementStore, utilsStore, filterStore} = useInjectStores();

    const formatNumber = utilsStore.formatNumber,
        toCurrency = utilsStore.toCurrency,
        getTotalsPercentage = utilsStore.getTotalsPercentage,
        toPercent = utilsStore.toPercent;
    const declineFilter = filterStore.declineFilter;

    const store = useLocalObservable(() => ({
        isLoading: false,
        widgetData: null,
        error: {},
        currentYYYY: '',
        prevYYYY: '',

        setNewQueryTest(newQueryTest) {
            const {status, data, error} = newQueryTest;
            if (status === 'loading') this.isLoading = true;
            if (data) {
                this.prevYYYY = data.values.find(item => item.yr_order === 'Previous Yr').tax_yr;
                this.currentYYYY = data.values.find(item => item.yr_order === 'Current Yr')?.tax_yr || this.prevYYYY + 1;
                const tmp = [...data.values];
                const hasCurrentYr = data.values.map(item => item.yr_order).includes('Current Yr');

                if(!hasCurrentYr) {
                    data.values.forEach((prev) => {
                        tmp.push({yr_order: 'Current Yr', tax_yr: prev.tax_yr + 1, data_src: prev.data_src});
                    });
                }

                this.widgetData = tmp;
                this.isLoading = false;
            }
            if (error) this.isLoading = false;
        },
        formatNumber,
        toCurrency,
        getTotalsPercentage
    }));

    const newQueryTest = useGetWidgetData(
        {name: 'tax1099k', query: widgetService.tax1099},
        'data',
        dataManagementStore?.newQuery
    );

    useEffect(() => {
        store.setNewQueryTest(newQueryTest);
    }, [newQueryTest]);

    let currentYearString = `CURRENT YEAR - ${store.currentYYYY}`;
    let previousYearString = `PREVIOUS YEAR - ${store.prevYYYY}`;
    return (
        <Observer>
            {() => (
                <Layout>
                    <TableWrapper noBackground height={'auto'}>
                        <br/>
                        <TableHeader>{!store.isLoading && currentYearString}</TableHeader>
                        {store.isLoading && <LoadingSpinner size={'200px'}/>}
                        <TableContainer display={'flex'} margin="0 10px">
                            <div css={`
                              display: flex;
                              flex-direction: column;
                              margin: 0 5px;
                              min-width: 100%;
                            `}>
                                <table style={{minWidth: '100%', borderCollapse: 'collapse'}}>
                                    {!store.isLoading && (
                                        <tbody style={{minWidth: '100%'}}>
                                            <tr  style={{borderBottom: '2px solid white', minWidth: '100%'}}>
                                                <th className="sticky-first"></th>
                                                <th className="sticky-second">
                                                    Tax Year
                                                </th>
                                                <th>JAN</th>
                                                <th>FEB</th>
                                                <th>MAR</th>
                                                <th>APR</th>
                                                <th>MAY</th>
                                                <th>JUN</th>
                                                <th>JUL</th>
                                                <th>AUG</th>
                                                <th>SEP</th>
                                                <th>OCT</th>
                                                <th>NOV</th>
                                                <th>DEC</th>
                                                <th>YTD TOTAL</th>
                                                <th>YTD RATIO</th>
                                            </tr>
                                            {store.widgetData?.length > 0 && store.widgetData.map((row, index) => (
                                                row.yr_order === 'Current Yr' ? (
                                                    <tr key={_get(row, 'data_src') + _get(row, 'tax_yr')}>
                                                        <td className="sticky-first" style={{textAlign: 'left', minWidth:' 200px', padding: index <= 1 ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'data_src', 0)}</td>
                                                        <td className="sticky-second" style={{textAlign: 'center', padding: index <= 1 ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'tax_yr', 0)}</td>
                                                        <td style={{padding: index <= 1 ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'jan', 0) ? toCurrency(_get(row, 'jan', 0)) : ''}</td>
                                                        <td style={{padding: index <= 1 ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'feb', 0) ? toCurrency(_get(row, 'feb', 0)) : ''}</td>
                                                        <td style={{padding: index <= 1 ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'mar', 0) ? toCurrency(_get(row, 'mar', 0)) : ''}</td>
                                                        <td style={{padding: index <= 1 ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'apr', 0) ? toCurrency(_get(row, 'apr', 0)) : ''}</td>
                                                        <td style={{padding: index <= 1 ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'may', 0) ? toCurrency(_get(row, 'may', 0)) : ''}</td>
                                                        <td style={{padding: index <= 1 ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'jun', 0) ? toCurrency(_get(row, 'jun', 0)) : ''}</td>
                                                        <td style={{padding: index <= 1 ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'jul', 0) ? toCurrency(_get(row, 'jul', 0)) : ''}</td>
                                                        <td style={{padding: index <= 1 ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'aug', 0) ? toCurrency(_get(row, 'aug', 0)) : ''}</td>
                                                        <td style={{padding: index <= 1 ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'sep', 0) ? toCurrency(_get(row, 'sep', 0)) : ''}</td>
                                                        <td style={{padding: index <= 1 ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'oct', 0) ? toCurrency(_get(row, 'oct', 0)) : ''}</td>
                                                        <td style={{padding: index <= 1 ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'nov', 0) ? toCurrency(_get(row, 'nov', 0)) : ''}</td>
                                                        <td style={{padding: index <= 1 ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'dec', 0) ? toCurrency(_get(row, 'dec', 0)) : ''}</td>
                                                        <td style={{padding: index <= 1 ? '5px 10px' : '5px 10px 10px 10px'}}>{toCurrency(_get(row, 'ytd_total', 0))}</td>
                                                        <td style={{padding: index <= 1 ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'var_ratio', 0)}</td>
                                                    </tr>
                                                ) : null
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </TableContainer>
                    </TableWrapper>
                    <div style={{paddingTop: '7rem'}}></div>
                    <TableWrapper noBackground height={'auto'}>
                    <TableHeader>{store.isLoading ? previousYearString : `PREVIOUS YEAR - ${store.prevYYYY || ''}`}</TableHeader>
                        {store.isLoading && <LoadingSpinner size={'200px'}/>}
                        <TableContainer display={'flex'} margin="0 10px">
                            <div css={`
                              display: flex;
                              flex-direction: column;
                              margin: 0 5px;
                              min-width: 100%;
                            `}>
                                <table style={{minWidth: '100%', borderCollapse: 'collapse'}}>
                                    {!store.isLoading && (
                                        <tbody style={{minWidth: '100%'}}>
                                            <tr  style={{borderBottom: '2px solid white', minWidth: '100%'}}>
                                                <th className="sticky-first"></th>
                                                <th className="sticky-second">
                                                    Tax Year
                                                </th>
                                                <th>JAN</th>
                                                <th>FEB</th>
                                                <th>MAR</th>
                                                <th>APR</th>
                                                <th>MAY</th>
                                                <th>JUN</th>
                                                <th>JUL</th>
                                                <th>AUG</th>
                                                <th>SEP</th>
                                                <th>OCT</th>
                                                <th>NOV</th>
                                                <th>DEC</th>
                                                <th>YTD TOTAL</th>
                                                <th>YTD RATIO</th>
                                            </tr>
                                            {store.widgetData?.length > 0 && store.widgetData.map((row, index) => {
                                                let isSumable = false;
                                                if(index === 5 || index === 6) {
                                                    isSumable = true;
                                                }
                                                return (
                                                    row.yr_order === 'Previous Yr' ? (
                                                        <tr key={_get(row, 'data_src') + _get(row, 'tax_yr')}>
                                                            <td className="sticky-first" style={{textAlign: 'left', minWidth:' 200px', padding: isSumable ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'data_src', 0)}</td>
                                                            <td className="sticky-second" style={{textAlign: 'center', padding: isSumable ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'tax_yr', 0)}</td>
                                                            <td style={{padding: isSumable ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'jan', 0) ? toCurrency(_get(row, 'jan', 0)) : ''}</td>
                                                            <td style={{padding: isSumable ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'feb', 0) ? toCurrency(_get(row, 'feb', 0)) : ''}</td>
                                                            <td style={{padding: isSumable ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'mar', 0) ? toCurrency(_get(row, 'mar', 0)) : ''}</td>
                                                            <td style={{padding: isSumable ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'apr', 0) ? toCurrency(_get(row, 'apr', 0)) : ''}</td>
                                                            <td style={{padding: isSumable ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'may', 0) ? toCurrency(_get(row, 'may', 0)) : ''}</td>
                                                            <td style={{padding: isSumable ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'jun', 0) ? toCurrency(_get(row, 'jun', 0)) : ''}</td>
                                                            <td style={{padding: isSumable ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'jul', 0) ? toCurrency(_get(row, 'jul', 0)) : ''}</td>
                                                            <td style={{padding: isSumable ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'aug', 0) ? toCurrency(_get(row, 'aug', 0)) : ''}</td>
                                                            <td style={{padding: isSumable ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'sep', 0) ? toCurrency(_get(row, 'sep', 0)) : ''}</td>
                                                            <td style={{padding: isSumable ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'oct', 0) ? toCurrency(_get(row, 'oct', 0)) : ''}</td>
                                                            <td style={{padding: isSumable ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'nov', 0) ? toCurrency(_get(row, 'nov', 0)) : ''}</td>
                                                            <td style={{padding: isSumable ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'dec', 0) ? toCurrency(_get(row, 'dec', 0)) : ''}</td>
                                                            <td style={{padding: isSumable ? '5px 10px' : '5px 10px 10px 10px'}}>{toCurrency(_get(row, 'ytd_total', 0))}</td>
                                                            <td style={{padding: isSumable ? '5px 10px' : '5px 10px 10px 10px'}}>{_get(row, 'var_ratio', 0)}</td>
                                                        </tr>
                                                    ) : null
                                                );
                                            }
                                            )}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </TableContainer>
                    </TableWrapper>
                </Layout>
            )}
        </Observer>
    );
});

export default Details;
const Layout = styled.div`
    th {
        color: ${({theme}) => theme.baseColors.whiteAlpha8};
        font-size: 14px;
        font-weight: bold;
        flex: 1;
    }
    tr:nth-child(3) {
        border-bottom: solid 1px white;
    }
    td {
        padding: 10px 5px 10px 10px;
        text-align: right;
        min-width: 100px;
    }
    .sticky-first {
        background: #002136;
        position: -webkit-sticky; /* For Safari */
        position: sticky;
        left: 0;
        z-index: 2; /* ensures the sticky cell is above other content */
    }
    
    /* Second sticky column */
    .sticky-second {
        background: #002136;
        position: -webkit-sticky; /* For Safari */
        position: sticky;
        left: 220px; /* Adjust this value based on the actual width of the first sticky column */
        z-index: 2;
    }
`;
