import React, {useEffect} from 'react';
import {Observer, observer, useLocalStore} from 'mobx-react-lite';
import {Notes, Table} from 'components/UI';
import {autorun} from 'mobx';
import {useHistory, useLocation} from 'react-router-dom';
import {_get, _upperFirst, useInjectStores, buildColDef} from 'utils/utils';
import {widgetService} from 'services';

const initialData = {txns: [], count: 0};

const SsDeclTxns = observer(() => {
    const history = useHistory();
    const location = useLocation();
    const {dataManagementStore, uiStore, userStore, utilsStore, widgetStore} = useInjectStores();

    const widgetModel = widgetStore.ssDeclTxnsWidgetModel,
        toCurrencyFormatter = utilsStore.toCurrencyFormatter,
        toDateFormatter = utilsStore.toDateFormatter,
        toPercent = utilsStore.toPercent,
        setTxnDetails = dataManagementStore.setTxnDetails,
        fetchTransaction = dataManagementStore.fetchTransaction,
        toggleShowTxnDetailModal = uiStore.toggleShowTxnDetailModal,
        paginatorModel = widgetModel.paginatorModel;

    const orderByOptions = [
        {key: 1, text: 'Cust Name', value: 'custName'},
        {key: 2, text: 'Invoice Num', value: 'invNum'},
        {key: 3, text: 'Decl Rsn', value: 'respCodeSname'},
        {key: 4, text: 'Txn Date', value: 'txnDt'},
    ];

    function cellStyle(params) {
        if (params.value < 0) {
            return {color: '#FF2B49'};
        } else {
            // return { color: 'rgb(69, 232, 133)' };
        }
    }

    const store = useLocalStore(() => ({
        fileName: 'declined-txns',
        loading: false,
        widgetData: initialData,
        rowData: [],
        columnDefs: [],
        txnCount: 0,
        async getData() {
            store.loading = true;
            try {
                const res = await widgetModel.fetchWidgetData(widgetService.declines1);

                store.widgetData = res.data;

                store.columnDefs = store.widgetData.count ? Object.keys(store.widgetData.values[0]).map(key => {
                    let autoWidth = store.widgetData.values[0][key].length >= 15 ? store.widgetData.values[0][key].length * 10.5 : store.widgetData.values[0][key].length * 12;
                    // let autoWidth = store.widgetData.values[0][key].length >= key.length ? store.widgetData.values[0][key].length >= 15 ? store.widgetData.values[0][key].length * 10.5 : store.widgetData.values[0][key].length * 12 : key.length >= 15 ? key.length * 10.5 : key.length * 12;
                    if(autoWidth > 350) {
                        autoWidth = 350;
                    }
                    return buildColDef(utilsStore, key, autoWidth > 100 ? autoWidth : 100);
                }) : [];

                store.columnDefs.unshift({
                    headerName: 'Row',
                    field: 'Index',
                    pinned: 'left',
                    checkboxSelection: true,
                    filter: false
                });
        
                store.columnDefs.unshift({
                  headerName: 'Details',
                  field: 'details',
                  filter: false,
                  pinned: 'left',
                  cellRenderer: (params) => {
                    const data = params.data,
                      button = document.createElement('button');
                    button.innerText = 'Details';
                    button.className = 'apply';
                    button.addEventListener('click', async () => {
                      toggleShowTxnDetailModal(data.proc_txn_id, params.rowIndex, true);
                      setTxnDetails(data);
                    });
                    return button;
                  }
                });

                store.rowData = store.widgetData.values.map((txn, index) => {
                    txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
                    return {
                        ...txn,
                    };
                });

                store.txnCount = store.widgetData.count;
            } catch (error) {
                console.error('error', error);
                if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                    userStore.logout(history, location);
                }
            }
            store.loading = false;
        },
        orderByOptions,
        dataManagementStore,
        widgetModel,
        paginatorModel,
        api: null,
        gridColumnApi: null,
        saveColumnState() {
            let savedState = store.gridColumnApi.getColumnState();
            savedState = JSON.stringify(savedState);
            if (savedState) {
                window.localStorage.setItem('decl-details-state', savedState);
            }
        },
        setColumnState() {
            let savedState = window.localStorage.getItem('decl-details-state');
            savedState = JSON.parse(savedState);
            if (savedState && store.gridColumnApi) {
                store.gridColumnApi.applyColumnState({state: savedState, applyOrder: true});
            }
        },
        resetColumnState() {
            window.localStorage.removeItem('decl-details-state');
            store.gridColumnApi.resetColumnState();
        },
        get gridOptions() {
            return {
                enableCharts: true,
                enableRangeSelection: true,
                groupMultiAutoColumn: true,
                rowHeight: 33,
                headerHeight: 33,
                rowSelection: 'multiple',
                // masterDetail: true,
                sideBar: {
                    position: 'left',
                    toolPanels: [
                        {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                        },
                        {
                            id: 'filters',
                            labelDefault: 'Filters',
                            labelKey: 'filters',
                            iconKey: 'filter',
                            toolPanel: 'agFiltersToolPanel',
                        },
                    ],
                },
                reactNext: true,
                defaultColDef: {
                    sortable: false,
                    filter: true,
                    resizable: true,
                    suppressMenu: true
                },
                columnDefs: store.columnDefs,

                onGridReady: (params) => {
                    store.api = params.api;
                    store.gridColumnApi = params.columnApi;
                    store.setColumnState();
                },
                onFirstDataRendered: (params) => {
                    setTimeout(params.columnApi.autoSizeColumns(['details', 'Index']));
                },
                overlayLoadingTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
                overlayNoRowsTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
            };
        },
    }));

    useEffect(
        () =>
            autorun(async () => {
                if (store.dataManagementStore.newQuery) {
                    paginatorModel.setPageNumber(1);
                    await store.getData();
                }
            }),
        []
    );

    return (
        <Observer>
            {() => (
                <Table
                    store={store}
                    fetchTableData={store.getData}
                    rowData={store.rowData}
                    loading={store.loading}
                    /**export**/
                    initiateTxnsExport={store.widgetModel.initiateTxnsExport}
                    exportInfo={store.widgetModel.exportInfo}
                    /** Paginator-Specific Functions **/
                    txnCount={store.txnCount}
                    paginator={paginatorModel}
                    enableBrowserTooltips={true}
                    /** OrderBy Properties **/
                    addOrderBy={store.widgetModel.addOrderBy}
                    orderByArray={store.widgetModel.orderByModel}
                    orderByOptions={store.orderByOptions}
                    resetOrderBy={store.widgetModel.resetOrderBy}
                />
            )}
        </Observer>
    );
});

export default SsDeclTxns;
